import { useEffect, useRef, useState } from 'react';
import styles from './Editor.module.css'
import { fabric } from "fabric";
import Controls from './Controls';
import Webcam from './Webcam';
import Modal from '../Ui/Modal';
import IconClose from '../Ui/Icons/IconClose';
import Form from './Form';
import Spinner from '../Ui/Spinner';

let canvas, markerBrush;
let pointerStart = 0
let isDrawing = false
const distanceThreshold = 100
let pressureApplied = 0
let group;

export default function Editor(props) {
  const containerRef = useRef()
  const canvasWrapRef = useRef()
  const canvasRef = useRef()
  const cursorRef = useRef()

  const nameRef = useRef();
  const emailRef = useRef();

  const [error, setError] = useState()


  const [layoutPicker, setLayoutPicker] = useState(true)
  const [layout, setLayout] = useState('full')
  const layoutRef = useRef()

  const [modalActive, setModalActive] = useState(false)
  const [clearWarningActive, setClearWarningActive] = useState(false)
  const [shareActive, setShareActive] = useState(false)
  const [formActive, setFormActive] = useState(false)

  const [mode, setMode] = useState('text')
  const modeRef = useRef('text')

  const [currentColor, setCurrentColor] = useState('#000000')
  const colorRef = useRef('#000000')
  const [currentSize, setCurrentSize] = useState(20)
  const sizeRef = useRef(20)

  const [currentSticker, setCurrentSticker] = useState('1')
  const stickerRef = useRef('1')

  const [webCamActive, setWebcamActive] = useState(false);
  

  useEffect(() => {
    initFabric();
    document.addEventListener('keyup', (e) => {
      if (e.key === 'Backspace') {
        deleteSelected();
      }
    })
  }, [])
  
  const initFabric = () => {
    canvas = new fabric.Canvas('canvas', {
      selection: false,
      isDrawingMode: true
    });
    setSize() // Make sure the size is the same aspect ratio as the 3D model

    canvas.on('mouse:down', (event) => {
      pointerStart = event.e.clientX
      
      if (!group && modeRef.current === 'draw') {
        isDrawing = true
        group = new fabric.Group()
        canvas.add(group)
      }
    })

    canvas.on('mouse:move', (event) => {
      if (!window.Modernizr.touchevents) {
        moveCursor(event.e)
      }

      if (isDrawing && modeRef.current === 'draw') {
        const changeX = Math.abs(pointerStart - event.pointer.x)
        
        if (changeX > distanceThreshold) {
          pointerStart = event.pointer.x
          pressureApplied = 0
        } else {
          pressureApplied += 0.1
          if (pressureApplied >= 6) {
            pressureApplied = 0
          }
        }
      }
    })

    canvas.on('mouse:up', e => {
      if (modeRef.current === 'sticker') {
        addImage(e.pointer.x, e.pointer.y)
      }
      if (modeRef.current === 'text') {
        addText(e.pointer.x, e.pointer.y)
      }
      if(modeRef.current === 'draw') {
        
      } 

      if (isDrawing) {
        const objects = canvas.getObjects()
        const lastObject = objects[objects.length - 1]
        canvas.remove(lastObject)
        group.addWithUpdate(lastObject)
        
        
        group = null
      }

      isDrawing = false
      pressureApplied = 0
    })

    // Select and delete
    // canvas.on('selection:created', e => {
    //   activeObjects = e.selected
    // })
    // canvas.on('selection:updated', e => {
    //   activeObjects = e.selected
    // })
    // canvas.on('selection:cleared', e => {
    //   activeObjects.length = 0
    // })


    markerBrush = new fabric.PencilBrush(canvas)
    // markerBrush.strokeLineJoin = 'miter'
    // markerBrush.strokeMiterLimit = 20

    canvas.freeDrawingBrush = markerBrush
    canvas.freeDrawingBrush.width = 20
    canvas.freeDrawingCursor = 'grab'

    // Get an item by index
    canvas.item(0)
    // Get all items
    canvas.getObjects()

    addDefaultText()
  }

  const moveCursor = (e) => {
    if (modeRef.current === 'draw') {
      cursorRef.current.style.left = `${e.clientX - 50}px`
      cursorRef.current.style.top = `${e.clientY - 15}px`
    } else if (modeRef.current === 'sticker') {
      cursorRef.current.style.left = `${e.clientX - 70}px`
      cursorRef.current.style.top = `${e.clientY - 70}px`
    }
  }

  function addImage(x,y) {
    const scale = 0.8
    fabric.Image.fromURL(`/editor/stickers/sticker-${stickerRef.current}.png`, image => {
      image.scale(scale)
      image.left = x - (image.width * scale / 2)
      image.top = y - (image.height * scale / 2)

      canvas.add(image).setActiveObject(image);
      startMoveMode();

      // disableTransform()
    });
  }

  const disableTransform = () => {
    canvas.selection = false
    const allObjects = canvas.getObjects();

    allObjects.forEach((object) => {
      object.lockMovementX = true
      object.lockMovementY = true
      object.lockScalingX = true
      object.lockScalingY = true
      object.lockRotation = true
      object.hasBorders = false
      object.hasControls = false
    })
  }

  const setSize = () => {
    const wrapperBounds = containerRef.current.getBoundingClientRect()
    const canvasBounds = canvasWrapRef.current.getBoundingClientRect()

    const wrapperWidth = wrapperBounds.width
    const wrapperHeight = wrapperBounds.height
    let canvasWidth = canvasBounds.width
    let canvasHeight = canvasBounds.height

    // if (layoutRef.current === 'square') {
    //   let size;
    //   if (window.innerWidth > window.innerHeight) {
    //     size = window.innerHeight - 100
    //   } else {
    //     size = window.innerWidth - 100
    //   }

    //   canvasWrapRef.current.style.width = `${size}px`
    //   canvasWrapRef.current.style.height = `${size}px`
    //   canvasWidth = size
    //   canvasHeight = size
    // } else if (layoutRef.current === 'portrait') {
    //   let height, width;
    //   if (window.innerWidth > window.innerHeight) {
    //     height = window.innerHeight - 100
    //     width = height * 0.56
    //   } else {
    //     width = window.innerWidth - 180
    //     height = width / 0.56

    //     if (height > window.innerHeight) {
    //       height = window.innerHeight - 180
    //       width = height * 0.56
    //     }
    //   }

    //   canvasWrapRef.current.style.width = `${width}px`
    //   canvasWrapRef.current.style.height = `${height}px`
    //   canvasWidth = width
    //   canvasHeight = height
    // }

    canvas.setDimensions({width: canvasWidth, height: canvasHeight})
    
    const imgWidth = 1200;
    const imgHeight = 1600;

    canvas.setBackgroundImage('./editor/lined-paper.jpg', canvas.renderAll.bind(canvas), {
      top: 0,
      left: 0,
      originX: 'left',
      originY: 'top',
      scaleX: canvas.getWidth() / imgWidth,
      scaleY: canvas.getHeight() / imgHeight
    });
  }






  function startMarkerMode() {
    canvas.freeDrawingBrush = markerBrush
    canvas.isDrawingMode = true
    setMode('draw')
    modeRef.current = 'draw'
    canvas.freeDrawingBrush.width = currentSize
    canvas.freeDrawingBrush.color = currentColor
    disableTransform()
  }

  function addDefaultText() {
    canvas.isDrawingMode = false
    setMode('text')
    modeRef.current = 'text'
    disableTransform()

    const canvasWidth = canvas.getWidth();
    const canvasHeight = canvas.getHeight();
    const fontSize = canvasWidth * 0.045;
    const topOffset = canvasHeight * 0.025;

    const textbox1 = new fabric.Textbox('the diary of', {
      left: canvasWidth / 2,
      top: topOffset,
      width: canvasWidth,
      textAlign: 'center',
      fontSize: fontSize,
      editable: false,
      selectable: false,
      hoverCursor: "default"
    });
    canvas.add(textbox1)
    textbox1.centerH();

    const textbox2 = new fabric.Textbox(props.name.split(" ")[0], {
      left: canvasWidth / 2,
      top: topOffset * 2.3,
      width: canvasWidth,
      textAlign: 'center',
      fontSize: fontSize,
      editable: false,
      selectable: false,
      hoverCursor: "default"
    });
    canvas.add(textbox2)
    textbox2.centerH();
  }

  function startTextMode() {
    canvas.isDrawingMode = false
    setMode('text')
    modeRef.current = 'text'
    disableTransform()

    addText();
  }

  function addText(x,y) {
    const object = canvas.getActiveObject();
    if (!object) {
      const textbox = new fabric.Textbox('', {
        left: x,
        top: y,
        width: 100,
        fontSize: 18
      });
      canvas.add(textbox).setActiveObject(textbox);
    }
  }

  function startWebcamMode() {
    canvas.isDrawingMode = false
    setMode('webcam')
    modeRef.current = 'webcam'
    enableTransform()

    setWebcamActive(true)
  }


  function startMoveMode() {
    canvas.isDrawingMode = false
    setMode('move')
    modeRef.current = 'move'
    enableTransform()
  }


  function enableTransform() {
    canvas.selection = true
    const allObjects = canvas.getObjects();

    allObjects.forEach((object) => {
      object.lockMovementX = false
      object.lockMovementY = false
      object.lockScalingX = false
      object.lockScalingY = false
      object.lockRotation = false
      object.hasBorders = true
      object.hasControls = true
    })
  }

  function startStickerMode() {
    canvas.isDrawingMode = false
    setMode('sticker')
    modeRef.current = 'sticker'
    disableTransform()
  }

  function removeLastObject() {
    const totalObjects = canvas.getObjects().length
    if (totalObjects > 0) {
      canvas.remove(canvas.item(totalObjects - 1))
    }
  }

  const deleteSelected = () => {
    const object = canvas.getActiveObject();
    
    if (object && !object.isEditing) {
      canvas.remove(object)
    }
  }


  function handleColorChange(e) {
    setCurrentColor(e.target.value)
    colorRef.current = e.target.value
    canvas.freeDrawingBrush.color = e.target.value
  }

  function handleSizeChange(e) {
    const size = parseInt(e.target.value)
    setCurrentSize(size)
    sizeRef.current = size
    canvas.freeDrawingBrush.width = parseInt(size)
  }
  function handleStickerChange(value) {
    console.log(value)
    setCurrentSticker(value)
    stickerRef.current = value

    addImage(100, 100)
  }

  function showClearWarning() {
    setClearWarningActive(true)
  }
  function closeClearWarning() {
    setClearWarningActive(false)
  }

  function clearCanvas() {
    const objects = canvas.getObjects()

    objects.forEach(object => {
      console.log(object)
      canvas.remove(object)
    })
    setClearWarningActive(false)
  }

  function downloadImage() {
    setShareActive(true)


    const hiddenCanvas = document.createElement('canvas');
    hiddenCanvas.width = 1200;
    hiddenCanvas.height = 1600;
    const destCtx = hiddenCanvas.getContext('2d');
    destCtx.drawImage(canvasRef.current, 0, 0, hiddenCanvas.width, hiddenCanvas.height);


    const ext = "jpg";
    const base64 = hiddenCanvas.toDataURL({
      format: ext,
      enableRetinaScaling: !window.Modernizr.touchevents // increases file size by about 10x
    });
    const link = document.createElement("a");
    link.href = base64;
    link.download = `diary-entry.${ext}`;
    link.click();

    // props.addDiaryPage(base64)
  }

  const uploadImage = async () => {
    setFormActive(true);
    submitEntry();
  }

  const handleWebcamCapture = (content) => {
    const group = new fabric.Group();
    fabric.Image.fromURL(content, image => {
      image.scale(0.183)
      image.left = 20;
      image.top = 20;

      group.addWithUpdate(image);

      fabric.Image.fromURL('/editor/polaroid-frame.png', frame => {
        frame.scale(0.4)
        frame.left = 0;
        frame.top = 0;

        group.addWithUpdate(frame);

        canvas.add(group).setActiveObject(group);
        setWebcamActive(false)
        startMoveMode();
      })
    });
  }

  const closeWebcam = () => {
    setWebcamActive(false);
  }

  const closeForm = () => {
    setFormActive(false)
  }

  const submitEntry = (e) => {
    window.grecaptcha.ready(function() {
      window.grecaptcha.execute("6LdJ6NsoAAAAAC5c1MvoLbTuIm4eUv0N8krB8dfe", {action: 'click'}).then(async (token) => {

        const hiddenCanvas = document.createElement('canvas');
        hiddenCanvas.width = 1200;
        hiddenCanvas.height = 1600;
        const destCtx = hiddenCanvas.getContext('2d');
        destCtx.drawImage(canvasRef.current, 0, 0, hiddenCanvas.width, hiddenCanvas.height);
      

        const ext = "jpg";
        const datat_url = hiddenCanvas.toDataURL({
          format: ext,
          enableRetinaScaling: !window.Modernizr.touchevents // increases file size by about 10x
        });
        

        const blob = await (await fetch(datat_url)).blob();

        const formData = new FormData();
        formData.append('name', props.name);
        formData.append('email', props.email);
        formData.append('city', props.city);
        formData.append('token', token)
        // formData.append('image_data', datat_url);
        formData.append('file_input', blob, 'diary-entry.jpg');

        const res = await fetch('/pages', {
          method: 'POST',
          body: formData
        })

        if (!res.ok) {
          console.log('error')
        }

        const data = await res.json()
        props.handleNewPage(data);
      })
    })
  }


  return(
    <div className={styles.wrapper}>

      <div ref={containerRef} className={styles.paint} >
        <div ref={canvasWrapRef} className={styles.canvasWrap} data-layout={layout}>
          <canvas ref={canvasRef} id="canvas" className={styles.canvas} width='800' height='800'></canvas>
        </div>
      </div>

      {!window.Modernizr.touchevents && (
        <div ref={cursorRef} className={styles.customCursor} data-mode={mode} data-sticker={currentSticker}></div>
      )}

      {webCamActive && <Webcam handleWebcamCapture={handleWebcamCapture} />}

      <Controls 
        mode={mode}
        currentColor={currentColor}
        currentSize={currentSize}
        currentSticker={currentSticker}
        showClearWarning={showClearWarning}
        handleColorChange={handleColorChange}
        handleSizeChange={handleSizeChange}
        handleStickerChange={handleStickerChange}
        startMarkerMode={startMarkerMode}
        startTextMode={startTextMode}
        startMoveMode={startMoveMode}
        startWebcamMode={startWebcamMode}
        startStickerMode={startStickerMode}
        addImage={addImage}
        undo={removeLastObject}
        downloadImage={downloadImage}
        uploadImage={uploadImage}
        closeWebcam={closeWebcam}
      />


      <Modal
        active={clearWarningActive}
        allowClose={true}
        close={closeClearWarning}
      >
        <p>
          Are you sure you want to clear the entire design?<br/>Your work will be lost.
        </p>
    
        <button className='btn' onClick={clearCanvas}>Clear Design</button>
      </Modal>

      {formActive && (
        <Modal
          active={true}
          allowClose={false}
          close={closeForm}
        >
          <div className='form-block text-handwritting'>
            <h2>Submit Diary Entry</h2>
            <p>
              Your diary entry is beeing saved...
            </p>

            <div className='spinner-small'>
              <Spinner />
              </div>
          </div>
          
      
          
        </Modal>
      )}

      <div className={styles.close} onClick={props.closeEditor}><IconClose /></div>
    </div>
  )
}