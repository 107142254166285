import { useState } from 'react'
import styles from './Controls.module.css'

import IconDownload from '../Ui/Icons/IconDownload'
import IconTrash from '../Ui/Icons/IconTrash'
import IconMove from '../Ui/Icons/IconMove'
import IconTextbox from '../Ui/Icons/IconTextbox'
import IconUndo from '../Ui/Icons/IconUndo'
import IconWebcam from '../Ui/Icons/IconWebcam'
import ToolOptions from './ToolOptions'
import IconDraw from '../Ui/Icons/IconDraw'
import IconImage from '../Ui/Icons/IconImage'

function Controls(props) {
  const [toolOptions, setToolOptions] = useState(props.mode)

  const handleToolChange = (e) => {
    const tool = e.target.dataset.tool;
    setToolOptions(tool);

    if (tool === 'draw') {
      setToolOptions('draw')
      props.startMarkerMode();
    } else if (tool === 'sticker') {
      setToolOptions('sticker')
      props.startStickerMode();
    } else if (tool === 'webcam') {
      props.startWebcamMode();
    } else if (tool === 'text') {
      props.startTextMode();
    } else if (tool === 'move') {
      props.startMoveMode()
    }

    if (tool !== 'webcam') {
      props.closeWebcam();
    }
  }

  const closeToolOptions = () => {
    setToolOptions(null);
  }

  const selectSticker = (e) => {
    console.log(e.target.value)
    props.handleStickerChange(e.target.value);
    setToolOptions('move')
  }
  

  return(
    <>
      <div className={styles.controls}>

        <button className={styles.control} onClick={handleToolChange} data-tool='text' data-active={props.mode === 'text'}>
          <IconTextbox />
        </button>

        <button className={styles.control} onClick={handleToolChange} data-tool='draw' data-active={props.mode === 'draw'}>
          <IconDraw />
        </button>

        <button className={styles.control} onClick={handleToolChange} data-tool='sticker' data-active={props.mode === 'sticker'}>
          <IconImage />
        </button>
        
        <button className={styles.control} onClick={handleToolChange} data-tool='webcam' data-active={props.mode === 'webcam'}>
          <IconWebcam />
        </button>
        
        <button className={styles.control} onClick={handleToolChange} data-tool='move' data-active={props.mode === 'move'}>
          <IconMove />
        </button>
      
        <button className={styles.control} onClick={props.undo}>
          <IconUndo />
        </button>

        <button className={styles.control} onClick={props.showClearWarning}>
          <IconTrash />
        </button>
        
        {/* <button className={styles.control} onClick={props.downloadImage}>
          <IconDownload />
        </button> */}
          
      </div>

      <div className={styles.cta}>
        <button className='btn btn--paper' onClick={props.downloadImage}>Download Image</button>
        <button className='btn btn--paper' onClick={props.uploadImage}>Upload to Diary</button>
      </div>
      
      {toolOptions === 'draw' && (
        <ToolOptions 
          close={closeToolOptions}
        >
          <>
            <label>SIZE</label>
            <input
              type='range'
              name="brush_size"
              defaultValue={props.currentSize}
              min='10'
              max='50'
              onChange={props.handleSizeChange}
            />
          </>
          <>
            <label htmlFor="head">COLOR</label><br/>
            <input
              type="color"
              id="head"
              name="brush_color"
              defaultValue={props.currentColor}
              onChange={props.handleColorChange}
            />
          </>
        </ToolOptions>
      )}
      

      {toolOptions === 'sticker' && (
        <ToolOptions 
          close={closeToolOptions}
        >
          <div className={styles.radioGroup}>
            {['1','2','3'].map(sticker => (
              <div key={`sticker_${sticker}`} className={styles.radioOption}>
                <input
                  id={`sticker-select-${sticker}`}
                  type='radio'
                  name='sticker'
                  defaultValue={sticker}
                  onChange={selectSticker}
                  checked={props.currentSticker === sticker}
                />
                <label htmlFor={`sticker-select-${sticker}`}>
                  <img src={`/editor/stickers/sticker-${sticker}.png`} />
                </label>
              </div>
            ))}
          </div>
          <>
            
          </>
        </ToolOptions>
      )}
    </>
  )
}

export default Controls