import { useEffect, useRef, useState } from 'react'
import styles from './Webcam.module.css'
import Spinner from '../Ui/Spinner'

export default function Webcam(props) {
  const stream = useRef();
  const previewRef = useRef();
  const webcamPreview = useRef();
  const webcamCanvas = useRef();
  const webcamPhoto = useRef();

  const [cameraEnabled, setCameraEnabled] = useState(false);
  const [cameraReady, setCameraReady] = useState(false);

  const [webcamWidth, setWebcamWidth] = useState();
  const [webcamHeight, setWebcamHeight] = useState();

  const [frameSize, setFrameSize] = useState(); 

  const [imageCaptured, setImageCaptured] = useState(false)


  useEffect(() => {
    startWebcam();
  }, [])

  const startWebcam = async () => {
    try {
      const constraints = { video: true, audio: false };
      stream.current = await navigator.mediaDevices.getUserMedia(constraints);
      setCameraEnabled(true)

      webcamPreview.current.addEventListener("canplay", e => {
        setCameraReady(true)
        const computedWidth = webcamPreview.current.getBoundingClientRect().width;

        setWebcamWidth(computedWidth)
        const computedHeight = (webcamPreview.current.videoHeight / webcamPreview.current.videoWidth) * computedWidth;
        setWebcamHeight(computedHeight)

        webcamPreview.current.play();
      })
      
      webcamPreview.current.srcObject = stream.current;
    } catch (err) {
      console.log('cant get stream')
    }
  }

  const disableWebcam = () => {
    stream.current.getVideoTracks()[0].stop();
    webcamPreview.current.src = '';
    setCameraReady(false)
    setCameraEnabled(false)
    setFrameSize('portrait')
    clearPhoto();
  }

  const clearPhoto = () => {
    const context = webcamCanvas.current.getContext("2d");
    context.fillStyle = "#AAA";
    context.fillRect(0, 0, 200, 200);
  
    const data = webcamCanvas.current.toDataURL("image/png");
    webcamPhoto.current.setAttribute("src", data);

    // setImageCaptured(false);
    // setImageRendered(false);
    // setImageData(null);
  }

  const captureFrame = () => {
    const context = webcamCanvas.current.getContext("2d");
    if (webcamWidth && webcamHeight) {
      webcamCanvas.current.width = 1080;
      webcamCanvas.current.height = 1080;

      const adjustedW = (webcamWidth * 1080) / webcamHeight;

      const previewBounds = previewRef.current.getBoundingClientRect();
      const videoBounds = webcamPreview.current.getBoundingClientRect();

      const offsetX = (previewBounds.width / 2) - (webcamWidth / 2);
      const offsetY = videoBounds.top - previewBounds.top;

      const offsetYAdjusted = (1080 * offsetY) / previewBounds.height;
      const offsetXAdjusted = (1080 * offsetX) / previewBounds.width;

      context.scale(-1, 1); // Flip to correct mirroring
      context.drawImage(webcamPreview.current, -offsetXAdjusted, 0, -adjustedW, 1080);
      context.restore();



      const data = webcamCanvas.current.toDataURL("image/jpg");

      setImageCaptured(true);
      
      props.handleWebcamCapture(data)
    } else {
      clearPhoto();
    }
  }

  return (
    <div className={styles.wrapper}>

      <div ref={previewRef} className={styles.preview}>
        <canvas ref={webcamCanvas} className={styles.tempCanvas}></canvas>
        <video ref={webcamPreview} className={styles.prev} playsInline></video>
        {/* <img ref={webcamPhoto} /> */}

        {cameraEnabled && !imageCaptured && <button onClick={captureFrame} className={styles.capture}></button>}
      
        {cameraEnabled && !cameraReady && (
          <div className={styles.loader}>
            <Spinner color='white' />
          </div>
        )}
      </div>
    </div>
  )
}