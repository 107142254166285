import IconClose from '../Ui/Icons/IconClose'
import styles from './ToolOptions.module.css'

export default function ToolOptions(props) {
  return (
    <div className={styles.wrapper}>
      <div className={styles.flyOutTitle}>{props.title}</div>

      {props.children.map((option, i) => (
        <div className={styles.option} key={`option_${i}`}>
          {option}
        </div>
      ))}
      
      <div onClick={props.close} className={styles.closeFlyout}><IconClose /> </div>
    </div>
  )
}