import styles from './Admin.module.css'
import moment from 'moment'

export default function Table(props) {
  const approvePage = async (e) => {
    const res = await fetch(`/pages/${e.target.dataset.pageid}/approve`, {
      method: 'PUT',
      headers: {
       'Content-Type': 'applicaition/json',
       'Authorization': `Password ${props.password}`
      }
    })

    if (!res.ok) {
      return console.log('Admin approve error')
    }
    
    props.getPages();
  }

  const deletePage = async (e) => {
    const res = await fetch(`/pages/${e.target.dataset.pageid}/reject`, {
      method: 'PUT',
      headers: {
       'Content-Type': 'applicaition/json',
       'Authorization': `Password ${props.password}`
      }
    })
    
    if (!res.ok) {
      return console.log('Admin reject error')
    }
    
    props.getPages();
  }

  return (
    <table>
      <thead>
        <tr>
          <th>DATE ADDED</th>
          <th>ID</th>
          <th>NAME</th>
          <th>IMAGE</th>
          <th>STATUS</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {props.pages.map((page, i) => (
          <tr key={`page_${i}`}>
            <td>{moment(page.createdAt).format("YYYY-MM-DD")}</td>
            <td>{page.id}</td>
            <td>{page.name}</td>
            <td>
              <div className={styles.tableImgWrap}>
              <img src={page.image_url} />
              </div>
            </td>
            <td>{page.status}</td>
            <td>
              <button onClick={approvePage} data-pageid={page._id}>APPROVE</button>
              <button onClick={deletePage} data-pageid={page._id}>REJECT</button>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  )
}