import styles from './Welcome.module.css'
import IconAudio from '../Ui/Icons/IconAudio'

export default function Welcome(props) {
  return (
    <div className={styles.wrapper}>
      <div className={styles.content}>
        <p>
          Pre-save for a chance to win tickets to the one night only intimate anniversary celebration
        </p>
        <div className="patchbay-patch" data-id="654183ecd4f4e917c0178973"></div>
      </div>
    </div>
  )
}