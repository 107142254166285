import classes from './LoadingBar.module.css'

export default function LoadingBar(props) {
  return(
    <div className={classes.wrapper} data-fade={props.transition}>
      <div className={classes.bar}>
        <div className={classes.text}>
          EXPERIENCE LOADING...
        </div>
      </div>
     </div>
  )
}