import { useEffect, useRef, useState } from "react"
import * as THREE from 'three'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js'
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';

import styles from './Book.module.css'
import IconAngleLeft from '../Ui/Icons/IconAngleLeft'
import IconAngleRight from '../Ui/Icons/IconAngleRight'

let scene, camera, renderer, textureLoader, modelLoader;

let tempVector, raycaster, pointer;

let model

let book, cover, backCover, dummyPage, tab1, tab2;

let zoomCamera;

const pages = [];

const objects = [];

export default function Book(props) {
  const sceneRef = useRef();

  const movingPageIndexRef = useRef(-2);
  const [movingPageIndex, setMovingPageIndex] = useState(-2);
  const [currentPage, setCurrentPage] = useState();
  
  useEffect(() => {
    initGraphics();
    initControls();
    animate();
  }, [])

  useEffect(() => {
    if (props.loading) {
      zoomCamera = true;
      camera.targetPosition = camera.homePosition;
    }
  }, [props.loading])

  useEffect(() => {
    if (props.ui === 'book' && props.userPages) {
      setupUserPages();
      turnPage('left');
    }
  }, [props.ui, props.userPages])
  

  useEffect(() => {
    movingPageIndexRef.current = movingPageIndex;
  }, [movingPageIndex])

  useEffect(() => {
    if (props.newPage) {
      addPage('new', props.newPage.image_url);
      jumpToPage('new')
    }
  }, [props.newPage])
  





  const initGraphics = () => {
    scene = new THREE.Scene();
    camera = new THREE.PerspectiveCamera(60, window.innerWidth / window.innerHeight, 0.001, 1000);
    
    if (window.innerWidth > 800) {
      camera.position.y = 1.1;
    } else {
      camera.position.y = 1.4;
    }
    camera.lookAt(0,0,0);

    camera.homePosition = new THREE.Vector3();
    camera.homePosition.copy(camera.position);

    camera.startingPosition = new THREE.Vector3(camera.position.x, camera.position.y + 0.3, camera.position.z);
    camera.position.copy(camera.startingPosition);

    camera.targetPosition = new THREE.Vector3();
    camera.targetPosition.copy(camera.startingPosition);


    renderer = new THREE.WebGLRenderer({alpha: true, antialias: true});
    renderer.setSize(window.innerWidth, window.innerHeight);
    renderer.setPixelRatio(window.devicePixelRatio);
    renderer.shadowMap.enabled = true;

    sceneRef.current.appendChild(renderer.domElement);
    window.addEventListener('resize', resize, false);


    // LIGHTS
    const ambientLight = new THREE.AmbientLight( 0xFFFFFF, 2);
    scene.add( ambientLight );

    const dirLight = new THREE.DirectionalLight(0xFFFFFF, 0.5);
    dirLight.position.set(3, camera.position.y + 8, 2);
    dirLight.target.position.set(0, 0, 0);
    dirLight.castShadow = true;
    dirLight.shadow.bias = -0.005;
    dirLight.shadow.camera.top = 4;
    dirLight.shadow.camera.bottom = -4;
    dirLight.shadow.camera.left = -4;
    dirLight.shadow.camera.right = 4;
    dirLight.shadow.camera.near = 1;
    dirLight.shadow.camera.far = 10;
    scene.add(dirLight);
    scene.add(dirLight.target);

    // const helper = new THREE.DirectionalLightHelper( dirLight, 5 );
    // scene.add( helper );
    // scene.add( new THREE.CameraHelper( dirLight.shadow.camera ) );
    // const controls = new OrbitControls( camera, renderer.domElement)


    // INTERACTION
    raycaster = new THREE.Raycaster();
		pointer = new THREE.Vector2();
    tempVector = new THREE.Vector3();


    // MODELS
    textureLoader = new THREE.TextureLoader();
    modelLoader = new GLTFLoader();
    const dracoLoader = new DRACOLoader();
    dracoLoader.setDecoderPath('https://www.gstatic.com/draco/versioned/decoders/1.5.6/'); 
    modelLoader.setDRACOLoader( dracoLoader );

    modelLoader.load('https://d1grxtbx9md071.cloudfront.net/models/ak-diary_v2.glb', gltf => {
      model = gltf.scene;

      model.traverse(node => {
        if (node.isMesh) {
          node.castShadow = true;
          node.receiveShadow = true;

          if (node.name.includes('btn')) {
            objects.push(node)
          }
        }
      })

      const pageWidth = 0.75;

      book = model.getObjectByName('book-control');

      book.openPosition = new THREE.Vector3();
      book.openPosition.copy(book.position);

      book.leftPosition = new THREE.Vector3(book.position.x + (pageWidth / 2), book.position.y, book.position.z);
      book.rightPosition = new THREE.Vector3(book.position.x - (pageWidth / 2), book.position.y, book.position.z);
      book.activeSide = 'right';

      book.startingPosition = new THREE.Vector3(book.position.x - (pageWidth / 2), book.position.y, book.position.z);
      
      book.targetPosition = new THREE.Vector3();
      book.targetPosition.copy(book.startingPosition);

      book.position.copy(book.startingPosition)
      
      // FRONT COVER
      cover = model.getObjectByName('front-cover');
      cover.rightPosition = new THREE.Vector3();
      cover.rightPosition.copy(cover.position);

      cover.rightQuaternion = new THREE.Quaternion();
      cover.rightQuaternion.copy(cover.quaternion);

      cover.leftQuaternion = new THREE.Quaternion(0, 0, 1, 0);

      cover.targetQuaternion = new THREE.Quaternion();
      cover.targetQuaternion.copy(cover.quaternion);


      // FRONT COVER
      backCover = model.getObjectByName('back-cover');


      
      // First add the tab to the pages array
      tab1 = model.getObjectByName('tab-1');
      addPage('tab', null, null, tab1)


      // Add song pages
      // model.traverse(node => {
      //   if (node.name.includes('song-page') && !node.isMesh) {
      //     console.log(node)
      //     addPage('song-page', null, null, node)
      //   }
      // })
      const songPage1 = model.getObjectByName('song-page-1')
      addPage('song-page', null, null, songPage1)
      const songPage2 = model.getObjectByName('song-page-2')
      addPage('song-page', null, null, songPage2)
      const songPage3 = model.getObjectByName('song-page-3')
      addPage('song-page', null, null, songPage3)
      const songPage4 = model.getObjectByName('song-page-4')
      addPage('song-page', null, null, songPage4)
      const songPage5 = model.getObjectByName('song-page-5')
      addPage('song-page', null, null, songPage5)
      const songPage6 = model.getObjectByName('song-page-6')
      addPage('song-page', null, null, songPage6)
      const songPage7 = model.getObjectByName('song-page-7')
      addPage('song-page', null, null, songPage7)
      const songPage8 = model.getObjectByName('song-page-8')
      addPage('song-page', null, null, songPage8)
      const songPage9 = model.getObjectByName('song-page-9')
      addPage('song-page', null, null, songPage9)
      const songPage10 = model.getObjectByName('song-page-10')
      addPage('song-page', null, null, songPage10)
      const songPage11 = model.getObjectByName('song-page-11')
      addPage('song-page', null, null, songPage11)
      const songPage12 = model.getObjectByName('song-page-12')
      addPage('song-page', null, null, songPage12)
      const songPage13 = model.getObjectByName('song-page-13')
      addPage('song-page', null, null, songPage13)
      const songPage14 = model.getObjectByName('song-page-14')
      addPage('song-page', null, null, songPage14)
      const songPage15 = model.getObjectByName('song-page-15')
      addPage('song-page', null, null, songPage15)
      const songPage16 = model.getObjectByName('song-page-16')
      addPage('song-page', null, null, songPage16)
      const songPage17 = model.getObjectByName('song-page-17')
      addPage('song-page', null, null, songPage17)
      const songPage18 = model.getObjectByName('song-page-18')
      addPage('song-page', null, null, songPage18)
      const songPage19 = model.getObjectByName('song-page-19')
      addPage('song-page', null, null, songPage19)
      const songPage20 = model.getObjectByName('song-page-20')
      addPage('song-page', null, null, songPage20)





      
      
      // model.traverse(node => {
      //   if ( node.name.includes('song-page') && !node.isMesh ) {
      //     console.log(node.name)
      //     // addPage('song-page', null, null, node)
      //   }
      // })


      // Add fan tab
      tab2 = model.getObjectByName('tab-2');
      addPage('tab', null, null, tab2)





      // DUMMY PAGE
      dummyPage = model.getObjectByName('page-control');
      dummyPage.visible = false;








      scene.add(model)
      props.handleModelsLoaded()
    });
  }

  const setupUserPages = () => {
    for (let i = 0; i < props.userPages.length; i += 2) {
      let frontImg, backImg;


      if (props.userPages[i + 1]) {
        backImg = props.userPages[i + 1].image_url;
      } else {
        backImg = null;
      }
      addPage('user-page', props.userPages[i].image_url, backImg)
    }

    const lastPage = pages[pages.length - 1];
    backCover.position.y = lastPage.position.y - 0.05;
  }

  const addPage = (category, frontImg, backImg, object) => {
    const offSetY = -0.001;
    let page;

    if ( (category === 'song-page' || category === 'tab') && object) {
      page = object;
    } else {
      page = dummyPage.clone();
      page.visible = true; // because we made the original invisible
    }
    
    
   
    let lastPage, lastPageBack, useBlankPage;
    if (category === 'new') {
      lastPage = lastPage = pages[pages.length - 1];
      lastPageBack = lastPage.children.find(x => x.name === 'page-back');

      if (lastPageBack && lastPageBack.isBlank) {
        useBlankPage = true;
      }
    }


    
    page.position.y = offSetY * pages.length;
    const pageLeftHeightOffset = -offSetY * pages.length


    if (category === 'new' && useBlankPage) {
      // There last side of the last page is blank, replace its material instead of adding a whole new page
      const newPageTexture = textureLoader.load(frontImg);
      const newPageMaterial = new THREE.MeshBasicMaterial({map: newPageTexture, side: THREE.DoubleSide });
      lastPageBack.material = newPageMaterial;
      lastPageBack.isBlank = false;
    } else {
      page.rightPosition = new THREE.Vector3();
      page.rightPosition.copy(page.position);
      
      page.leftPosition = new THREE.Vector3(page.position.x, pageLeftHeightOffset, page.position.z);
      page.targetPosition = new THREE.Vector3();
      page.targetPosition.copy(page.position)

      page.rightQuaternion = new THREE.Quaternion();
      page.rightQuaternion.copy(page.quaternion);

      page.leftQuaternion = new THREE.Quaternion(0, 0, 1, 0);

      page.targetQuaternion = new THREE.Quaternion();
      page.targetQuaternion.copy(page.quaternion);

      if (category === 'user-page' || category === 'new') {
        const front = page.children.find(x => x.name === 'page-front');
        if (!frontImg) {
          // Reload the material here because creating it sooner causes overlaps on page turn
          const textureBlank = textureLoader.load('https://d1grxtbx9md071.cloudfront.net/textures/lined-paper_blank.jpg');
          const blankPageMaterial = new THREE.MeshBasicMaterial({map: textureBlank, side: THREE.DoubleSide });

          front.material = blankPageMaterial;
          front.isBlank = true;
        } else {
          const textureFront = textureLoader.load(frontImg);
          const materialFront = new THREE.MeshBasicMaterial({map: textureFront, side: THREE.DoubleSide });
          front.material = materialFront;
        }

        const back = page.children.find(x => x.name === 'page-back');

        if (!backImg) {
          // Reload the material here because creating it sooner causes overlaps on page turn
          const textureBlank = textureLoader.load('https://d1grxtbx9md071.cloudfront.net/textures/lined-paper_blank.jpg');
          const blankPageMaterial = new THREE.MeshBasicMaterial({map: textureBlank, side: THREE.DoubleSide });
          
          back.material = blankPageMaterial;
          back.isBlank = true;
        } else {
          const textureBack = textureLoader.load(backImg);
          const materialBack = new THREE.MeshBasicMaterial({map: textureBack, side: THREE.DoubleSide });
          back.material = materialBack;
        }
      }

      if (category === 'new') {
        page.category = 'user-page';
      } else {
        page.category = category;
      }
      
      
      pages.push(page)
      book.add(page)
    }
  }

  const jumpToPage = (pageName, pageLeftIndex, pageRightIndex) => {
    let targetPage, targetIndex;
    if (pageName === 'songs') {
      // targetPage = pages.find(page => page.name === 'song-page-2');
      targetIndex = pages.findIndex(page => page.name === 'song-page-2')
    } else if (pageName === 'fan-entries') {
      // targetPage = pages.find(page => page.category === 'user-page'); // Finds the first match
      targetIndex = pages.findIndex(page => page.category === 'user-page');
    } else if (pageName === 'new') {
      targetIndex = pages.length - 1;
    }

    let direction;
    if (pageName === 'new') {
      direction = 'left';
      const newPage = pages[targetIndex];
      const newPageBack = newPage.children.find(x => x.name === 'page-back');
      if (newPageBack.isBlank) {
        targetIndex -= 1;
      }

    } else if (targetIndex > pageRightIndex) {
      direction = 'left';
      targetIndex -= 1;
    } else {
      direction = 'right';
    }

    
    turnPage(direction, targetIndex, 'jump');
  }

  const turnPage = (direction, index, action) => {
    let targetIndex;

    if (index) {
      targetIndex = index;
    } else if (direction === 'left') {
      targetIndex = movingPageIndexRef.current + 1;

      if (targetIndex === pages.length) {
        return
      }
    } else if (direction === 'right') {
      if (movingPageIndexRef.current === -2) {
        return
      }

      targetIndex = movingPageIndexRef.current;
    }

    // Position Book
    if (targetIndex === -1 && direction === 'right') {
      book.targetPosition = book.startingPosition;
    } else {
      if (window.innerWidth > 800) {
        book.targetPosition = book.openPosition;
      } else if (targetIndex !== -1) {
        // MOBILE BOOK POSITIONING
        if (direction === 'left' && book.activeSide === 'right') {
          book.targetPosition = book.leftPosition;
          book.activeSide = 'left';
        } else if (direction === 'left' && book.activeSide === 'left') {
          book.targetPosition = book.rightPosition;
          book.activeSide = 'right';
        
          return // Dont turn the page just move the book into view
        } else if (direction === 'right' && book.activeSide === 'left') {
          book.targetPosition = book.rightPosition;
          book.activeSide = 'right';
        } else if (direction === 'right' && book.activeSide === 'right') {
          book.targetPosition = book.leftPosition;
          book.activeSide = 'left';

          return // Dont turn the page
        }
      }
    }

    


    // Turn pages
    let pageTurnDelay = 0;
    let coverTurnDelay = 0;
    let resetToCoverPosition = false;




    if (action) {
      // Turn all the pages before the target first
      const pagesToTurn = [];

      if (direction === 'left') {
        for (let i = 0; i < targetIndex; i++) {
          pagesToTurn.push({delay: pageTurnDelay, pageIndex: i})
          pageTurnDelay += 20;
        }

        for (let i = 0; i < pagesToTurn.length; i++) {
          const item = pagesToTurn[i];
          const page = pages[item.pageIndex];
          
          setTimeout(() => {
            page.targetQuaternion = page.leftQuaternion;
            page.targetPosition = page.leftPosition;
            page.isTurning = true;
            page.turnDirection = direction;
          }, item.delay);
        }
      } else {
        // console.log('go back')
        for (let i = pages.length - 1; i > targetIndex; i--) {
          pagesToTurn.push({delay: pageTurnDelay, pageIndex: i})
          pageTurnDelay += 20;
        }

        // console.log(pagesToTurn)

        for (let i = 0; i < pagesToTurn.length; i++) {
          const item = pagesToTurn[i];
          const page = pages[item.pageIndex];
          
          setTimeout(() => {
            page.targetQuaternion = page.rightQuaternion;
            page.targetPosition = page.rightPosition;
            page.isTurning = true;
            page.turnDirection = direction;
          }, item.delay);
        }
      }
    }
    // wait until all pages above are moved out of the way


    
      if (targetIndex === -1 || (targetIndex === 0 && direction === 'right') ) {
        if (direction === 'right') {
          resetToCoverPosition = true;
        }
        targetIndex = 0;
        pageTurnDelay = direction === 'left' ? 100 : 0;
        coverTurnDelay = direction === 'left' ? 0 : 100; // Slight delay to prevent texture flashing on overlap

        setTimeout(() => {
          cover.isTurning = true;
          cover.turnDirection = direction;
          cover.targetQuaternion = direction === 'left' ? cover.leftQuaternion : cover.rightQuaternion;
        }, coverTurnDelay);
      }
      
      pages.forEach(page => {
        page.active = false;
        page.currentSide = null; // maybe redundant/unneeded
      })

      const targetPage = pages[targetIndex];
      targetPage.isTurning = true;
      targetPage.turnDirection = direction;

      targetPage.active = true;
      targetPage.currentSide = direction; // if the page is on the left, the bottom is visisble, if it's on the right the top is visible

      const neighborPageIndex = targetIndex + (direction === 'left' ? 1 : -1);
      const neighborPage = pages[neighborPageIndex];
      if (neighborPage) {
        neighborPage.active = true;
        neighborPage.currentSide = direction === 'left' ? 'right' : 'left'; // opposit
      }

      setTimeout(() => {
        targetPage.targetQuaternion = direction === 'left' ? targetPage.leftQuaternion : targetPage.rightQuaternion;
        targetPage.targetPosition = direction === 'left' ? targetPage.leftPosition : targetPage.rightPosition;
      }, pageTurnDelay);
        
      
      if (resetToCoverPosition) {
        book.targetPosition = book.startingPosition;
        targetIndex -= 1; // Decrease here after moving the pages to reset
      }
      
      if (direction === 'left') {
        setMovingPageIndex(targetIndex)
      } else if (direction === 'right') {
        setMovingPageIndex(targetIndex - 1)
      }


  }


  const btnPageTurn = (e) => {
    const direction = e.target.dataset.direction;
    turnPage(direction)
  }


  const animate = () => {
    requestAnimationFrame(animate)
    camera.lookAt(0,0,0);

    if (zoomCamera) {
      camera.position.lerp(camera.targetPosition, 0.02)

      if (camera.position.distanceTo(camera.targetPosition) < 0.0001) {
        zoomCamera = false;
      }
    }

    if (book) {
      book.position.lerp(book.targetPosition, 0.09);
    }

    if (cover) {
      if (cover.isTurning) {
        cover.quaternion.slerp(cover.targetQuaternion, 0.09);

        if (cover.turnDirection === 'left' && cover.rotation.z > 3.13) {
          cover.isTurning = false;
        } else if (cover.turnDirection === 'right' && cover.rotation.z < 0.001) {
          cover.isTurning = false;
        }
      }
    }

    pages.forEach(page => {
      if (page.isTurning) {
        page.quaternion.slerp(page.targetQuaternion, 0.09);

        // page.position.lerp(page.targetPosition, 0.09);
        page.position.copy(page.targetPosition);

        if (page.turnDirection === 'left' && page.rotation.z > 3.11) {
          page.isTurning = false;
        } else if (page.turnDirection === 'right' && page.rotation.z < 0.002) {
          page.isTurning = false;
        }
      }
    })

    renderer.render(scene, camera);
  }

  const resize = () => {
    camera.aspect = window.innerWidth / window.innerHeight;
    camera.updateProjectionMatrix();
    renderer.setSize(window.innerWidth, window.innerHeight);
  }

  const initControls = () => {
    sceneRef.current.addEventListener( 'click', handleClick );

    if (window.Modernizr.touchevents) {
      sceneRef.current.addEventListener('touchstart', swipeStart, false);
      sceneRef.current.addEventListener('touchmove', swipe, false);
      sceneRef.current.addEventListener('touchend', swipeEnd, false);
    } else {
      sceneRef.current.addEventListener('pointerdown', swipeStart, false);
      sceneRef.current.addEventListener('pointermove', swipe, false);
      sceneRef.current.addEventListener('pointerup', swipeEnd, false);
    } 
  }


  const setPointerLocation = (e) => {
    const clientX = e.touches ? e.touches[0].clientX : e.clientX;
    const clientY = e.touches ? e.touches[0].clientY : e.clientY;

    pointer.x = ( clientX / renderer.domElement.clientWidth ) * 2 - 1;
    pointer.y = - ( clientY / renderer.domElement.clientHeight ) * 2 + 1;

    raycaster.setFromCamera( pointer, camera );
    
    if (model) {
      const intersectsObjects = raycaster.intersectObjects(objects);
      
      if (intersectsObjects.length > 0) {
        pointer.target = intersectsObjects[ 0 ];

        // if (!window.Modernizr.touchevents) {
        //   sceneRef.current.style.cursor = 'pointer';
        // }
      } else {
        pointer.target = null;

        // if (!window.Modernizr.touchevents) {
        //   sceneRef.current.style.cursor = 'auto';
        // }
      }
    }
  }

  const handleClick = (e) => {
    setPointerLocation(e);

    if (pointer.target && pointer.target.object) {
      const pointerObject = pointer.target.object;
      
      const pageLeft = pages.find(page => page.active && page.currentSide === 'left');
      const pageLeftIndex = pages.findIndex(page => page.active && page.currentSide === 'left')
      const pageRight = pages.find(page => page.active && page.currentSide === 'right');
      const pageRightIndex = pages.findIndex(page => page.active && page.currentSide === 'right')

      if (pageRight && pageLeft) {
        if (pageRight.name === 'song-page-1' && pointerObject.name === 'btn-vinyl') {
          window.open('https://AliciaKeys.lnk.to/diaryvinyl20', '_blank')
        }
        if (pageRight.name === 'song-page-1' && pointerObject.name === 'btn-webster-hall') {
          window.open('https://www.axs.com/events/510258/alicia-keys-tickets?skin=websterhall', '_blank')
        }
      }

      if (pageLeft && pointerObject.name.includes('btn-songs')) {
        jumpToPage('songs', pageLeftIndex, pageRightIndex)

        if (window.innerWidth < 800) {
          book.targetPosition = book.leftPosition;
          book.activeSide = 'left';
        }
      } else if (pageLeft && pointerObject.name.includes('btn-fan-entries')) {
        jumpToPage('fan-entries', pageLeftIndex, pageRightIndex)

        if (window.innerWidth < 800) {
          book.targetPosition = book.rightPosition;
          book.activeSide = 'right';
        }
      }
    }
  }








  let touchStartX, touchStartY = 0;
  let touchEndX, touchEndY = 0;
  let pointerActive = false;
  const swipeStart = (e) => {
    pointerActive = true;
    touchStartX = e.touches ? e.touches[0].clientX : e.clientX;
    touchStartY = e.touches ? e.touches[0].clientY : e.clientY;
    touchEndX = e.touches ? e.touches[0].clientX : e.clientX;
    touchEndY = e.touches ? e.touches[0].clientY : e.clientY;
  }

  const swipe = (e) => {
    setPointerLocation(e);

    if (pointerActive) {
      touchEndX = e.touches ? e.touches[0].clientX : e.clientX;
      touchEndY = e.touches ? e.touches[0].clientY : e.clientY;
    }
    
  }

  const swipeEnd = (e) => {
    pointerActive = false;
    const diffX = touchEndX - touchStartX;
    const swipeDistance = Math.abs(diffX);
    const direction = diffX < 0 ? 'left' : 'right';

    const pageMidPoint = window.innerWidth / 2;
    const activeAreaWidth = 50;

    let touchSide;
    if (touchStartX > pageMidPoint + activeAreaWidth) {
      touchSide = 'right'
    } else if (touchStartX < pageMidPoint - activeAreaWidth) {
      touchSide = 'left'
    } else {
      return
    }
    
    if (swipeDistance > 20 && touchSide === 'right' && direction === 'left') {
      turnPage('left')
    } else if (swipeDistance > 20 && touchSide === 'left' && direction === 'right') {
      turnPage('right')
    }
  }

  return (
    <>
      <div ref={sceneRef} className='webgl-wrapper'></div>
      {props.ui === 'book' && (
        <>
          <button onClick={btnPageTurn} className={styles.navControl} data-direction='right'><IconAngleLeft /></button>
          <button onClick={btnPageTurn} className={styles.navControl} data-direction='left'><IconAngleRight /></button>
        </>
      )}
        
    </>
  )
}