import { useEffect, useState } from "react";
import Book from "./components/Book/Book";
import Editor from "./components/PageEditor/Editor";
import Welcome from "./components/Screens/Welcome";
import ContactForm from "./components/Screens/ContactForm";
import LoadingBar from "./components/Ui/LoadingBar";
import Notification from "./components/Ui/Notification";

let presaveTemp = false;
let playerTriggered = false;

function App() {
  const [ui, setUi] = useState('welcome')
  const [patchbayReady, setPatchbayReady] = useState(false)
  const [audioReady, setAudioReady] = useState(false);
  const [presaved, setPresaved] = useState(false);
  const [modeslLoaded, setModelsLoaded] = useState(false)
  const [loading, setLoading] = useState(true)
  const [loadingTransition, setLoadingTransition] = useState(false)

  const [editorActive, setEditorActive] = useState(false);
  const [pages, setPages] = useState();
  const [newPage, setNewPage] = useState();
  const [pageConfirmation, setPageConfirmation] = useState();

  const [name, setName] = useState();
  const [email, setEmail] = useState();
  const [city, setCity] = useState();



  useEffect(() => {
    document.addEventListener('audioOptOut', () => {
    
    });
    document.addEventListener('patchbayReady', () => {
      // console.log('patchready')
      setPatchbayReady(true);
    })
    document.addEventListener('audioReady', () => {
      // console.log('audioready')
      setAudioReady(true);

      document.addEventListener('click', () => {
        if (!playerTriggered) {
          document.dispatchEvent(new CustomEvent('patchPlayAudio'));
          playerTriggered = true;
        }
      })
    });
    document.addEventListener('patchAction', (e) => {
      // console.log('patch action', e.detail)
      /// Patchbay is firing this twice for some reason
      if (!presaveTemp && e.detail.action === 'presave') {
        presaveTemp = true;
        setAudioReady(true);
        setPresaved(true);

        setUi('contact')
        //setUi('book')
        //getPages(); // Load here so you don't waste requests on visitors that wont actually view the pages
      }
      
    })
  })

  useEffect(() => {
    if (patchbayReady && modeslLoaded) {
      setLoadingTransition(true)
      setTimeout(() => {
        setLoading(false)
      }, 500)   
    }
  }, [patchbayReady, modeslLoaded])
  

  // PLACEHOLDER FOR TESTING
  const fakePresave = () => {
    presaveTemp = true;
    setAudioReady(true);
    setPresaved(true);

    setUi('contact')
  }




  

  const getPages = async () => {
    const res = await fetch('/pages')

    if (!res.ok) {
      console.log('Get page error')
    }

    const pageData = await res.json();
    setPages(pageData);
  }

  const openEditor = () => {
    setEditorActive(true)
  }

  const closeEditor = () => {
    setEditorActive(false)
  }

  const handleNewPage = (page) => {
    setEditorActive(false);
    setNewPage(page)
    setPageConfirmation(true)

    setTimeout(() => {
      setPageConfirmation(false)
    }, 2000);
    // Call db again? 
    //Maybe not needed if you already have the visuals, jsut add it to the model
  }
  
  const handleContactAdded = (userName, userEmail, userCity) => {
    setUi('book')
    getPages();

    setName(userName)
    setEmail(userEmail)
    setCity(userCity)
  }

  const handleModelsLoaded = () => {
    setModelsLoaded(true)
  }

  return (
      <>
        <Book 
          ui={ui} 
          userPages={pages} 
          newPage={newPage} 
          handleModelsLoaded={handleModelsLoaded} 
          loading={loading}
        />

        {ui === 'welcome' && <Welcome fakePresave={fakePresave} loading={loading} />}

        {editorActive && name && (
          <Editor 
            closeEditor={closeEditor} 
            handleNewPage={handleNewPage} 
            name={name}
            email={email}
            city={city}
          />
        )}

        {!editorActive && ui !== 'welcome' && ui !== 'contact' && <button onClick={openEditor} className='btn btn--paper addpage'>Add Page</button>}

        {ui === 'contact' && <ContactForm handleContactAdded={handleContactAdded} />}

        <div className="patchbay-player" data-id='65418404d4f4e917c0178a47'></div>

        {loading && <LoadingBar transition={loadingTransition} />}

        {pageConfirmation && <Notification>Page added!</Notification>}


      </>
  )
}

export default App;
