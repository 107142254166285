import { useState } from 'react'
import IconClose from './Icons/IconClose'
import styles from './Notification.module.css'

export default function Notification(props) {
  const [active, setActive] = useState(true);
  
  const close = () => {
    setActive(false)
  }

  return (
    <div className={styles.wrapper} data-active={active}>
      {props.children} 
      <div onClick={close} className={styles.close}>
        <IconClose />
      </div>
    </div>
  )
}