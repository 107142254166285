import { useEffect, useState } from "react";
import styles from './Admin.module.css'
import Spinner from '../Ui/Spinner';
import Table from "./Table";

export default function Admin() {
  const [passwordValid, setPasswordValid] = useState(window.location.href.includes('local') ? true : false)
  const [password, setPassword] = useState(window.location.href.includes('local') ? 'U1E69B3' : null )
  const [error, setError] = useState()
  const [pages, setPages] = useState();
  const [loading, setLoading] = useState(false);
  const [statusFilter, setStatusFilter] = useState('all');
 
  useEffect(() => {
    if (password && passwordValid) {
      getPages();
    }
  }, [password, passwordValid])

  useEffect(() => {
    getPages()
  }, [statusFilter])

  const getPages = async () => {
    setLoading(true)

    const res = await fetch(`/admin-pages?status=${statusFilter}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Password ${password}`
      }
    })

    setLoading(false)

    if (!res.ok) {
      return console.log('Admin error')
    }
    
    const data = await res.json();
    setPages(data)
  }

  

  const handlePasswordSubmit = async (e) => {
    e.preventDefault();

    const res = await fetch('/admin-password', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({password})
    })

    if (!res.ok) {
      return setError('Invalid password')
    }
    
    setPasswordValid(true);
  }

  const updateFilter = (e) => {
    const filter = e.target.dataset.filter;
    setStatusFilter(filter);
  }

  return (
    <div className={styles.wrapper}>

      <div className={styles.content}>
        {passwordValid && pages
          ? 
            <>
              <h1>Manage Fan Pages</h1>
              
              <div>
                <button onClick={updateFilter} data-filter='all'>All</button>
                <button onClick={updateFilter} data-filter='pending'>Pending</button>
                <button onClick={updateFilter} data-filter='approved'>Approved</button>
                <button onClick={updateFilter} data-filter='rejected'>Rejected</button>

                {loading && <Spinner />}
                {!loading && pages && <Table pages={pages} getPages={getPages} password={password} />}
              </div>
            </>
          : 
            <form onSubmit={handlePasswordSubmit}>
              <p>
                Enter your password:
              </p>
              <div>
                <input
                  type='password'
                  name='password'
                  onChange={e => setPassword(e.target.value)}
                />
              </div>
              <div>
                <p>{error}</p>
                <button type='submit'>SUBMIT</button>
              </div>
            </form>
        }
      </div>




    </div>
  )
}