import { useRef, useState } from "react";
import styles from './ContactForm.module.css';
import Spinner from "../Ui/Spinner";
// import 'react-phone-number-input/style.css'
// import PhoneInput from 'react-phone-number-input'
// import { isPossiblePhoneNumber } from 'react-phone-number-input'

export default function ContactForm(props) {
  const emailRef = useRef();
  // const phoneRef = useRef();
  const nameRef = useRef();
  const lastNameRef = useRef();
  const instagramRef = useRef();
  const cityRef = useRef();
  const stateRef = useRef();
  const birthdayRef = useRef();
  
  const [error, setError] = useState('')
  const [contactAdded, setContactAdded] = useState(false)
  const [submitting, setSubmitting] = useState(false)

  const state_options = ["AL",	"AK",	"AS",	"AZ",	"AR",	"CA",	"CO",	"CT",	"DE",	"DC",	"FL",	"GA",	"GU",	"HI",	"ID",	"IL",	"IN",	"IA",	"KS",	"KY",	"LA",	"ME",	"MD",	"MA",	"MI",	"MN",	"MS",	"MO",	"MT",	"NE",	"NV",	"NH",	"NJ",	"NM",	"NY",	"NC",	"ND",	"MP",	"OH",	"OK",	"OR",	"PA",	"PR",	"RI",	"SC",	"SD",	"TN",	"TX",	"UT",	"VT",	"VA",	"VI",	"WA",	"WV",	"WI",	"WY"]

  const handleSubmit = (e) => {
    e.preventDefault();

    setSubmitting(true);

    // if (!phone || !isPossiblePhoneNumber(phone)) {
    //   setError('Please add a valid phone number')
    // }
    const email = emailRef.current.value;
    const name = nameRef.current.value;
    const city = cityRef.current.value;
    
    if (!email || email === '' || !name || name === '' || !city || city === '') {
      setError('Please complete all fields')
      setSubmitting(false);
    } else {
      window.grecaptcha.ready(() => {
        window.grecaptcha.execute("6LdJ6NsoAAAAAC5c1MvoLbTuIm4eUv0N8krB8dfe", {action: 'submit'})
        .then(async (token) => {
          const body = {token, email, name, city}
          const res = await fetch('/contacts', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify(body)
          })

          setSubmitting(false);
          props.handleContactAdded( name, email, city);

          if (!res.ok) {
            console.log('Error')
          }

          

          const data = await res.json();
          
        })
      })
    }
  }

  return(
    <div className={styles.wrapper}>
      {contactAdded && !submitting && (
        <div className={styles.confirmation}>Your entry has been submitted!</div>
      )}

      {submitting && (
        <div className={styles.loader}>
          <Spinner />
          Submitting...
        </div>
      )}

      {!contactAdded && !submitting && (
        <form onSubmit={handleSubmit} className={styles.content}>
          <div className={styles.title}>
            <div className={styles.titleMain}>Track Saved!</div>
            <div className={styles.titleSub}>Add your contact info for a chance to attend a special show</div>
          </div>  

          <div className='input-row'>
            <label>Name</label>
            <input
              type='text'
              name='firstName'
              ref={nameRef}
            />
          </div>

          <div className='input-row'>
            <label>Email Address</label>
            <input
              type='email'
              name='email'
              ref={emailRef}
            />
          </div>

          <div className='input-row'>
            <label>City</label>
            <input
              type='text'
              name='city'
              ref={cityRef}
            />
          </div>

          <div className="error">{error}</div>
        
          <button className='btn btn--paper btn--block' type='submit'>Submit</button>
        </form>
      )}
    </div>
  )
}